<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Общая информация
        </h4>
      </div>

      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Тип блока"
              >
                <v-select v-model="settings.key" placeholder="Тип блока (ключ) EN" :options="['main_image', 'features', 'statistic']"></v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      
      <div class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Название"
              >
                <b-form-input
                  v-model="settings.name"
                  placeholder="Название"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div class="card">
      <div class="card-body">
        <div class="mb-1">
          Описание
        </div>
        <quill-editor
          v-model="settings.description"
        />
      </div>
    </div>
      
    </div>

    <div class="card">
      <div class="card-header" />
      <div class="card-body">
        <img v-if="settings.image && settings.image.fullPath" :src="settings.image.fullPath" alt="avatar" class="image">
      </div>
      <div class="card-body">
        <div class="upload-wrap">
          <file-uploader
            v-model="photo"
            :default="photo"
            title="Картинка"
          />
        </div>
      </div>
    </div>

    <b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1 save-button"
        :disabled="isloading"
        @click="saveItem"
      >
        <b-spinner
          v-if="isloading"
          small
        />
        {{ isloading ? 'Сохранение...' : 'Сохранить' }}
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
  VBPopover,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import crud_module from '../../../libs/crud_module'
import FileUploader from '@/global-components/FileUploader'
import vSelect from 'vue-select'

const setting_module = crud_module({
  entity_name: 'club-settings',
})

export default {
  name: 'CategoryPage',
  components: {
    quillEditor,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    FileUploader,
    vSelect
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
      isloading: false,
      settings: {},
      photo: null,
    }
  },
  async created() {
    if (this.$route.params.id === 'new') {
      return
    }
    try {
      await setting_module.fetchItem(this.$route.params.id, {
        query: {
          admin: '1',
        },
      }).then(result => {
        this.settings = result.data
      })
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    saveItem() {
      if (this.$route.params.id === 'new') {
        this.create()
      } else {
        this.update()
      }
    },
    async update() {
      try {
        await setting_module.updateItem(
          this.settings.id, 
          {...this.settings, imageId: this.photo}
        )
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Блок сохранен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'clubs-settings' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
    async create() {
      try {
        await setting_module.createItem({...this.settings, imageId: this.photo})
          .then(response => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Блок добавлен',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'clubs-settings' })
            return response
          })
          .catch(error => {
            this.isloading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            })
          })
      } catch (e) {
        this.isloading = false
        console.error(e)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.select {
  padding: 21px;
  padding-top: 0px;
  width: 100%;
}
.label {
  padding-left: 21px;
}
.font-size {
  font-size: inherit;
}
.image {
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
}

</style>
